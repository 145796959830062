import React from "react";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaEnvelope, FaPhone } from 'react-icons/fa';

export default function Footer() {
  return (
    <React.Fragment>
      <footer className="page-footer dark">
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <h5>Get started</h5>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <a href="/">Sign up</a>
                </li>
                <li>
                  <a href="/">Downloads</a>
                </li>
              </ul>
            </div>
            <div className="col-sm-3">
              <h5>About us</h5>
              <ul>
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact us</Link>
                </li>
                <li>
                  <a href="/">Reviews</a>
                </li>
              </ul>
            </div>
            <div className="col-sm-3">
              <h5>Contact Us</h5>
              <ul>
                <li>
                  <a href="https://www.facebook.com/profile.php?id=100063910964467">
                    <FaFacebook /> Facebook
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/kairosaurapharma/">
                    <FaInstagram /> Instagram
                  </a>
                </li>
                <li>
                  <a href="mailto: aurakairospharma20@gmail.com">
                    <FaEnvelope /> E-Mail
                  </a>
                </li>
                <li>
                  <a href="tel: 7846885019">
                    <FaPhone /> 91 7846885019
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-sm-3">
              <h5>Legal</h5>
              <ul>
                <li>
                  <a href="/">Terms of Service</a>
                </li>
                <li>
                  <a href="/">Terms of Use</a>
                </li>
                <li>
                  <a href="/">Privacy Policy</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <p>© 2021 kairosaurapharma. All rights reserved.</p>
        </div>
      </footer>
    </React.Fragment>
  );
}
