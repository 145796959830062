import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css';
import { Gallery, Item } from 'react-photoswipe-gallery';
import {Products} from '../pages/StaticProductsData';

const MyGalary = () => (
  <Gallery>
    {Products.map((item,index)=>(
      <Item
      original={item.image}
      thumbnail={item.image}
      width="1024"
      height="768"
      key={index}
    >
      {({ ref, open }) => (
        <img ref={ref} onClick={open} className="cus-thumb-img" src={item.image} alt={item.name} />
      )}
    </Item>
    ))}
  </Gallery>
)

export default MyGalary;