import Productimg from "../components/Productimg";

const Products = [
  {
    name: "KAIROS ALPHA",
    image: Productimg.KAIROS_ALPHA,
    about:
      "Alpha ketoanalogue is used to prescribe for the treatment of chronic kidney disease.",
  },
  {
    name: "KAIROS 90",
    image: Productimg.KAIROS_90,
    about:
      "Pro Biotic Capsules Kairos-90 can reduce liver aminotransferases, total cholestrol and improve insulin resistance in NAFLD patients.",
  },
  {
    name: "NEPHROSIS",
    image: Productimg.NEPHROSIS,
    about:
      "N-acetylcysteine is a medication used to treat condition such as ashthma and cystic fibrosis. As an inhalant, it helps to thin mucus and make it less paste-like.",
  },
  {
    name: "AURA PLUS",
    image: Productimg.AURA_PLUS,
    about:
      "This Medication is an iron supplement used to treat or prevent low blood levels of iron (such as those caused by anemia or pregnacy). Iron is an important mineral that the body needs to produce red blood cells and keep you in good health.",
  },
  {
    name: "kaira Q10",
    image: Productimg.kaira_Q10,
    about:
      "kaira-Q10 is used to treat nutritional deficiencies and also used in liver, heart and muscle problems. It transports long-chain fatty acids into the mitochondria so they can oxidized to produce energy.It also transports the toxic compounds generated out of this cellular organelle to prevent their accumulation.",
  },
  {
    name: "Aleska DSR",
    image: Productimg.Aleska_DSR,
    about:
      "Aleska-Dsr Tablet is commonly used for the diagnosis or treatment of Gas, heartburn, vomiting, difficulty in swallowing, bloating. It has some side effects such as Irritability, headache, dry mouth, breast pain.",
  },
  {
    name: "Nyrasis",
    image: Productimg.Nyrasis,
    about:
      "Sodium bicarbonate or Sodium citrate pills can help to keep kidney disease from getting worse. Healthy kidneys help keep your bicarbonate levels in balance. Metabolic acidosis has been identified as an independent risk factor for the progression of CKD.",
  },
  {
    name: "SYNA-400",
    image: Productimg.Syna,
    about:
      "Sevelamer is used to lower high blood phosphorus levels in  patients who are on dialysis due to several kidney disease. It removes some phosphate from your blood when the kidneys are not working properly. Sevelamer is in a class of medications called phosphate binders.",
  },
  {
    name: "Neurocos Forte",
    image: Productimg.Neurocos_Forte,
    about:
      "Methylcobalamin is used to treat vitamin B12 deficiency. Alpha-lipoic acid has strong antioxidant properties, which may reduce inflammation and skin aging, promote healthy nerve function, lower heart disease risk factors, and slow the progression of memory loss disorders.",
  },
  {
    name: "TIJOFI-TS 100",
    image: Productimg.Tijofi_Ts_100,
    about:
      "Torsemide is used to reduce extra fluid in the body caused by conditions such as heart failure, liver disease, and kidney disease.It is used to treat edema caused by various medical problems, including heart, kidney, or liver disease.",
  },
  {
    name: "TIJOFI-TS 10",
    image: Productimg.Tijofi_Ts_10,
    about:
      "Torsemide is used to reduce extra fluid in the body caused by conditions such as heart failure, liver disease, and kidney disease.It is used to treat edema caused by various medical problems, including heart, kidney, or liver disease.",
  },
  {
    name: "TIJOFI-TS 20",
    image: Productimg.Tijofi_Ts_20,
    about:
      "Torsemide is used to reduce extra fluid in the body caused by conditions such as heart failure, liver disease, and kidney disease.It is used to treat edema caused by various medical problems, including heart, kidney, or liver disease.",
  },
  {
    name: "SLOBIS LONG",
    image: Productimg.Alpha_ketoanalogue,
    about:
      "Alpha ketoanalogue is used to prescribe for the treatment of chronic kidney disease.",
  },
  {
    name: "KAIRA D3",
    image: Productimg.KAIRA_D3,
    about:
      "It is approved for use in conditions with abnormal, viscid ot inspissated mucous secretions such as pneumonia, brochitis, tracheobronchitis, cystic fibrosis, tracheostomy patients, postoperative pulmonary complications, posttraumatic chest conditions.",
  },
  {
    name: "KAIROS 30",
    image: Productimg?.KAIROS_30,
    about:
      "Pro Biotic Capsules Kairos-30 can reduce liver aminotransferases, total cholestrol and improve insulin resistance in NAFLD patients.",
  },
  {
    name: "Karol 0.25",
    image: Productimg?.Karol_0_25,
    about:
      "Karol 0.25 treats low calcium levels caused by kidney disease. It can also treat parathyroid gland conditions. Calcitriol increases the amount of calcium (vitamin D) in your body and helps regulate parathyroid levels.",
  },
  {
    name: "Aura Pro",
    image: Productimg?.AURA_PRO,
    about:
      "Aura Pro provides a diet rich in protein, which is vital for fighting infection, healing wounds and achieving a good nutritional status.",
  },
  {
    name: "AURA PRO RENAL",
    image: Productimg?.AURO_PRO_RENAL,
    about:
      "Renal Powder is a High Bioavailability of all Nutrients meant for patients with kidney disorders. it contains Zero Cholesterol & Trans Fat. It is ideal for Oral as well as Enteral (tube) Feed and also ensures Adequate Carbohydrate to maintain Protein level. It contains Zero sugar",
  },
  {
    name: "Nyrasis-500",
    image: Productimg?.Nyrasis_500,
    about:
      "Sodium bicarbonate or Sodium citrate pills can help to keep kidney disease from getting worse. Healthy kidneys help keep your bicarbonate levels in balance. Metabolic acidosis has been identified as an independent risk factor for the progression of CKD.",
  },
  {
    name: "TIJOFI-TS-40",
    image: Productimg?.TIJOFI_40,
    about:
      "Torsemide is used to reduce extra fluid in the body caused by conditions such as heart failure, liver disease, and kidney disease.It is used to treat edema caused by various medical problems, including heart, kidney, or liver disease.",
  },

  //new
  {
    name: "PRAZOHECT-2.5",
    image: Productimg?.PRAZOHECT_2,
    about:
      "This medication is used to manage and treat hypertension, benign prostatic hyperplasia, PTSD- associated nightmares, and the Raynaud phenomenon.",
  },
  {
    name: "PRAZOHECT-5",
    image: Productimg?.PRAZOHECT_5,
    about:
      "This medication is used to manage and treat hypertension, benign prostatic hyperplasia, PTSD- associated nightmares, and the Raynaud phenomenon.",
  },
  {
    name: "Proxitor-40",
    image: Productimg?.Proxitor,
    about:
      "Febuxostat is commonly used to treat gout, which is a form of arthritis caused by too much uric acid in your blood and joints. Febuxostat may be used when another medicine for gout.",
  },
  {
    name: "Tijofi-TS5",
    image: Productimg?.Tijofi_TS5,
    about:
      "Torsemide is used to help treat fluid retention (edema) and swelling that is caused by congestive heart failure, liver disease, kidney disease.",
  },
  {
    name: "Ferony-200",
    image: Productimg?.Ferony_200,
    about:
      "This tablet helps to treat severe bacterial infections such as respiratory tract infections, urinary tract infection, skin infections and gynaecological infections.",
  },
  {
    name: "Ferony-300",
    image: Productimg?.Ferony_300,
    about:
      "This Tablet helps treat severe bacterial infections such as respiratory tract infections, urinary tract infection, skin infections and gynaecological infections.",
  },
  {
    name: "KAIROS ALPHA DS",
    image: Productimg?.KAIROS_ALPHA_DS,
    about:
      "Alpha Ketoanalogue is used in the treatment of chronic kidney disease. It belongs to a class of drugs called nutritional supplements. It follows same catabolic pathways as amino acids and works by improving the metabolism of protein in the body,",
  },
];

const Company_name = "KAIROS AURA PHARMACEUTICALS";

const AboutCompany =
  "As one of the leading pharmaceutical companies, we are committed towards making the world a happier and healthier place by ensuring that our products serve mankind across different geographies. We endeavor to achieve the best quality standards in the pharmaceutical industry in india. Our total Quality management system adheres to regulations and we aim to developing novel and differentiating scientific solutions through innovative products which are designed to improve the quality of life.";

const Banner =
  "KAIROS AURA PHARMACEUTICALS is a  pharmaceutical firm with just a few items, but it is expanding under the skillful direction of our Managing Experts. We make and sell medications for nephology. The drug controller has granted KAIROS AURA PHARMACEUTICALS a license to promote medicinal goods.We have a large Godown for the storage of pharmaceutical items with skilled Packaging & Storage Managers, where the products have been exhibited and preserved very properly with the most recent packaging readily available countrywide.";

const Contact_us =
  "KAIROS AURA PHARMACEUTICALS provides 24 hour customer support, to cater the needs of our valued clients located all over India. Below you can find our contact details.";

const Slider =
  "We deliver affordable medications that meet various medical needs, our aim is to be a leader in the pharmaceutical industry in india and develop top quality, cost-effective medications which improve the lives of patients.";

export { Products, AboutCompany, Banner, Company_name, Contact_us, Slider };
