import React from "react";
import { Link } from "react-router-dom";

function ContactUs() {
  return (
    <React.Fragment>
      <section className="clean-block about-us">
        <div className="container">
          <div className="block-heading">
            <h2 className="text-info">Contact Us</h2>
            <p className="text-justify w-100">KAIROS AURA PHARMACEUTICALS provides 24 hour customer support, to cater the needs of our valued clients located all over India. Below you can find our contact details.</p>
            <Link to="/contact-us">
              <button className="btn btn-outline-dark btn-lg" type="button">
                Contact Form
              </button>
            </Link>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default ContactUs;
