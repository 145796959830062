import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from "../pages/Home";
import AboutUs from "../pages/AboutUsPage";
import ContactUsPage from "../pages/ContactUsPage";

const Main = () => {
    return (
      <Switch>
        <Route exact path='/' component={Home}></Route>
        <Route exact path='/about' component={AboutUs}></Route>
        <Route exact path='/contact-us' component={ContactUsPage}></Route>
      </Switch>
    );
  }
  export default Main;
