import React from "react";
import Navbar from "../components/Navbar";
import MyGalary from "../components/MyGalary";

function AboutUsPage() {
  return (
    <React.Fragment>
      <div className="body">
        <Navbar />
        <main className="page landing-page mt-5">
          <section className="clean-block about-us">
            <div className="container">
              <div className="block-heading">
                <h2 className="text-info">About Us</h2>
                <p className="text-justify w-100">
                  As one of the leading pharmaceutical companies, we are committed towards making the world a happier and healthier place by ensuring that our products serve mankind across different geographies.
                  We endeavor to achieve the best quality standards in the pharmaceutical industry in india.Our total Quality management system adheres to regulations and we aim to developing novel and differentiating scientific solutions through innovative products which are designed to improve the quality of life.
                </p>
              </div>
              <div className="block-heading">
                <h2 className="text-info">Gallery</h2>
                <MyGalary />
              </div>
            </div>
          </section>
        </main>
      </div>
    </React.Fragment>
  );
}

export default AboutUsPage;
