import KAIROS_ALPHA from "../assets/images/products/KAIROSALPHA.jpg";
import KAIROS_90 from "../assets/images/products/KAIROS-90.jpg";
import KAIROS_30 from "../assets/images/products/KAIROS_30.jpg";
import AURA_PRO from "../assets/images/products/AURA_PRO.jpg";
import KAIRA_D3 from "../assets/images/products/KAIRA_D3.jpg";
import Karol_0_25 from "../assets/images/products/Karol_0_25.jpg";
import NEPHROSIS from "../assets/images/products/NEPHROSIS.jpg";
import AURA_PLUS from "../assets/images/products/AURA_PLUS.jpg";
import KAIROS_AURA_logo from "../assets/images/logo/KAIROS_AURA_logo.png";
import kidney from "../assets/images/logo/kidney_banner1.jpg";
import kaira_Q10 from "../assets/images/products/KAIRA_Q10.jpg";
import Aleska_DSR from "../assets/images/products/Aleska-DSR.jpg";
import Nyrasis from "../assets/images/products/nyrasis.jpg";
import Syna from "../assets/images/products/syna-400.jpg";
import Neurocos_Forte from "../assets/images/products/Neurocos.jpg";
import Tijofi_Ts_100 from "../assets/images/products/Tijofi_Ts_100.jpg";
import Tijofi_Ts_10 from "../assets/images/products/Tijofi_Ts_10.jpg";
import Tijofi_Ts_20 from "../assets/images/products/Tijofi_Ts_20.jpg";
import AURO_PRO_RENAL from "../assets/images/products/AURO-PRO-RENAL.jpg";
import Alpha_ketoanalogue from "../assets/images/products/Alpha_ketoanalogue.jpg";
import Nyrasis_500 from "../assets/images/products/nyrasysis-500.webp";
import TIJOFI_40 from "../assets/images/products/tijofi-40.jpg";
// new
import PRAZOHECT_2 from "../assets/images/products/PRAZOHECT-2.5.jpg";
import PRAZOHECT_5 from "../assets/images/products/PRAZOHECT-5.jpg";
import Proxitor from "../assets/images/products/Proxitor-40.jpg";
import Tijofi_TS5 from "../assets/images/products/Tijofi-TS5.jpg";
import Ferony_200 from "../assets/images/products/Ferony-200.jpg";
import Ferony_300 from "../assets/images/products/Ferony-300.jpg";
import KAIROS_ALPHA_DS from "../assets/images/products/KAIROS_ALPHA_inner.jpg";

const images = {
  KAIROS_AURA_logo,
  kidney,
  KAIROS_ALPHA,
  KAIROS_90,
  KAIROS_30,
  Karol_0_25,
  AURA_PRO,
  KAIRA_D3,
  NEPHROSIS,
  AURA_PLUS,
  kaira_Q10,
  Aleska_DSR,
  Nyrasis,
  Syna,
  Neurocos_Forte,
  Tijofi_Ts_20,
  Tijofi_Ts_10,
  Tijofi_Ts_100,
  Alpha_ketoanalogue,
  AURO_PRO_RENAL,
  Nyrasis_500,
  TIJOFI_40,
  PRAZOHECT_2,
  PRAZOHECT_5,
  Proxitor,
  Tijofi_TS5,
  Ferony_200,
  Ferony_300,
  KAIROS_ALPHA_DS,
};

export default images;
